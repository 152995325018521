import { Conversations } from "@/components/conversations/conversations";
import { createFileRoute } from "@tanstack/react-router";

export function ConversationsRoute() {
  return <Conversations />;
}

export const Route = createFileRoute("/_authenticated/conversations/")({
  component: ConversationsRoute,
});
