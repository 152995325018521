import { createEvent, editEvent } from "@/api/events.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { formatDateForPicker } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import {
  EventInput,
  eventCreateZod,
} from "zora-api/concerns/events/events.zod";
import { ZoraEvent } from "zora-api/concerns/mobile/mobile.router";
import { ImageInput } from "../image-input/image-input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Textarea } from "../ui/textarea";
import { Icons } from "../ui/icons";

type EventFormProps =
  | {
      isEdit: true;
      initialValues: ZoraEvent;
    }
  | {
      isEdit: false;
      initialValues?: ZoraEvent;
    };

export function EventsForm({ isEdit, initialValues }: EventFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const eventId = initialValues?.id;
  const queryClient = useQueryClient();

  const form = useForm<EventInput>({
    resolver: zodResolver(eventCreateZod),
    defaultValues: {
      title: initialValues?.title || "",
      start: formatDateForPicker(initialValues?.start),
      end: formatDateForPicker(initialValues?.end),
      allDay: initialValues?.allDay || false,
      description: initialValues?.description || "",
      imageUrl: initialValues?.imageUrl || "",
    },
  });

  const { mutate: createEventMutation, isPending: isCreatingEvent } =
    useMutation({
      mutationFn: (event: EventInput) => createEvent(event),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["events"] });
        // @ts-expect-error - TODO: fix this
        navigate({ to: "/calendar/all/events" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { mutate: updateEventMutation, isPending: isUpdatingEvent } =
    useMutation({
      mutationFn: (event: EventInput & { id: string }) => editEvent(event),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["events"] });
        if (eventId)
          queryClient.invalidateQueries({ queryKey: ["event", eventId] });
        // @ts-expect-error - TODO: fix this
        navigate({ to: "/calendar/all/events" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const isSubmitting = isCreatingEvent || isUpdatingEvent;

  const onSubmit = (data: EventInput) => {
    if (isEdit && eventId) {
      updateEventMutation({
        ...data,
        id: eventId,
      });
    } else {
      createEventMutation({
        ...data,
      });
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Event" : "New Event"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button
              size="sm"
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
              Save Event
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Event</CardTitle>
                <CardDescription>
                  Create or edit an event to be displayed in the app.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Title</FormLabel>
                        <FormControl>
                          <Input
                            autoFocus
                            type="text"
                            className="w-full"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid gap-3 grid-cols-2">
                    <FormField
                      control={form.control}
                      name="start"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Start</FormLabel>
                          <FormControl>
                            <Input type="datetime-local" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="end"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>End</FormLabel>
                          <FormControl>
                            <Input type="datetime-local" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="allDay"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-1 leading-none">
                          <FormLabel>All Day Event</FormLabel>
                        </div>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea
                            className="w-full min-h-[200px]"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Event Image</CardTitle>
                <CardDescription>
                  Select an image for your event
                </CardDescription>
              </CardHeader>
              <CardContent>
                <FormField
                  name="imageUrl"
                  control={form.control}
                  render={({ field }) => (
                    <ImageInput
                      form={form}
                      field={field}
                      contentType="image/png"
                      displayType="card"
                    />
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button variant="outline" size="sm">
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            {isSubmitting ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
            Save Event
          </Button>
        </div>
      </Form>
    </div>
  );
}
