/* eslint-disable @typescript-eslint/no-unused-vars */
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "framer-motion";
import { Forward, Heart } from "lucide-react";
import { useEffect, useRef } from "react";
import {
  ChatBubble,
  ChatBubbleAction,
  ChatBubbleActionWrapper,
  ChatBubbleAvatar,
  ChatBubbleMessage,
  ChatBubbleTimestamp,
} from "../ui/chat/chat-bubble";
import { ChatMessageList } from "../ui/chat/chat-message-list";
import ChatBottombar from "./chat-bottombar";
import { Message, UserData } from "./data";
import { ConversationItem } from "zora-api/prisma/generated";
import { CreateConversationItemSchema } from "zora-api/concerns/conversations/conversations.zod";

interface ChatListProps {
  conversationItems: ConversationItem[];
  participantId: string;
  sendMessage: (newMessage: CreateConversationItemSchema) => void;
  isMobile: boolean;
}

const getMessageVariant = (messageName: string, selectedUserName: string) =>
  messageName !== selectedUserName ? "sent" : "received";

export function ChatList({
  conversationItems,
  participantId,
  sendMessage,
  isMobile,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [conversationItems]);

  const actionIcons = [
    { icon: DotsVerticalIcon, type: "More" },
    { icon: Forward, type: "Like" },
    { icon: Heart, type: "Share" },
  ];

  return (
    <div className="w-full overflow-y-auto h-full flex flex-col">
      <ChatMessageList ref={messagesContainerRef}>
        <AnimatePresence>
          {conversationItems.map((item, index) => {
            const variant = getMessageVariant(item.senderId, participantId);
            return (
              <motion.div
                key={index}
                layout
                initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
                animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
                transition={{
                  opacity: { duration: 0.1 },
                  layout: {
                    type: "spring",
                    bounce: 0.3,
                    duration: index * 0.05 + 0.2,
                  },
                }}
                style={{ originX: 0.5, originY: 0.5 }}
                className="flex flex-col gap-2 p-4"
              >
                {/* Usage of ChatBubble component */}
                <ChatBubble variant={variant}>
                  <ChatBubbleAvatar src={"message.avatar"} />
                  <ChatBubbleMessage isLoading={false}>
                    <ChatBubbleTimestamp
                      timestamp={"item.createdAt.toISOString()"}
                    />
                  </ChatBubbleMessage>
                  <ChatBubbleActionWrapper variant={variant}>
                    {actionIcons.map(({ icon: Icon, type }) => (
                      <ChatBubbleAction
                        className="size-7"
                        key={type}
                        icon={<Icon className="size-4" />}
                        onClick={() =>
                          console.log(
                            "Action " + type + " clicked for message " + index,
                          )
                        }
                      />
                    ))}
                  </ChatBubbleActionWrapper>
                </ChatBubble>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </ChatMessageList>
      <ChatBottombar
        isMobile={isMobile}
        sendMessage={sendMessage}
        participantId={participantId}
      />
    </div>
  );
}
