import {
  ConversationItemResponse,
  ConversationResponse,
  ConversationsResponse,
} from "zora-api/concerns/conversations/conversations.router";
import { axios, buildHeaders } from "./axios";
import { ParticipantType } from "zora-api/concerns/conversations/conversations.zod";

export const fetchConversations = async ({
  participantId,
  participantType,
}: {
  participantId: string;
  participantType: ParticipantType;
}): Promise<ConversationsResponse> => {
  const response = await axios.get<ConversationsResponse>(
    `/conversations/?participantId=${participantId}&participantType=${participantType}`,
    {
      headers: buildHeaders(),
    },
  );
  return response.data;
};

export const fetchConversation = async ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const response = await axios.get<ConversationResponse>(
    `/conversations/${conversationId}`,
    {
      headers: buildHeaders(),
    },
  );
  return response.data;
};

export const createConversationItem = async ({
  conversationId,
  senderId,
  type,
  textBody,
  json,
}: {
  conversationId: string;
  senderId: string;
  type: string;
  textBody: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: Record<string, any>;
}) => {
  const response = await axios.post<ConversationItemResponse>(
    `/conversations/${conversationId}/items`,
    { senderId, type, textBody, json },
    { headers: buildHeaders() },
  );
  return response.data;
};
