import { DropdownButton } from "@/components/ui/tiptap/Dropdown";
import { Icon } from "@/components/ui/icon";
import { Surface } from "@/components/ui/tiptap/surface";
import { Toolbar } from "@/components/ui/tiptap/toolbar";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import type { Language } from "@/components/tiptap/extensions/Ai";
import { useCallback } from "react";

const tones = [
  { name: "academic", label: "Academic", value: "academic" },
  { name: "business", label: "Business", value: "business" },
  { name: "casual", label: "Casual", value: "casual" },
  { name: "childfriendly", label: "Childfriendly", value: "childfriendly" },
  { name: "conversational", label: "Conversational", value: "conversational" },
  { name: "emotional", label: "Emotional", value: "emotional" },
  { name: "humorous", label: "Humorous", value: "humorous" },
  { name: "informative", label: "Informative", value: "informative" },
  { name: "inspirational", label: "Inspirational", value: "inspirational" },
  { name: "memeify", label: "Memeify", value: "meme" },
  { name: "narrative", label: "Narrative", value: "narrative" },
  { name: "objective", label: "Objective", value: "objective" },
  { name: "persuasive", label: "Persuasive", value: "persuasive" },
  { name: "poetic", label: "Poetic", value: "poetic" },
];

const languages = [
  { name: "arabic", label: "Arabic", value: "ar" },
  { name: "chinese", label: "Chinese", value: "zh" },
  { name: "english", label: "English", value: "en" },
  { name: "french", label: "French", value: "fr" },
  { name: "german", label: "German", value: "de" },
  { name: "greek", label: "Greek", value: "gr" },
  { name: "italian", label: "Italian", value: "it" },
  { name: "japanese", label: "Japanese", value: "jp" },
  { name: "korean", label: "Korean", value: "ko" },
  { name: "russian", label: "Russian", value: "ru" },
  { name: "spanish", label: "Spanish", value: "es" },
  { name: "swedish", label: "Swedish", value: "sv" },
  { name: "ukrainian", label: "Ukrainian", value: "ua" },
];

export type AIDropdownProps = {
  onSimplify: () => void;
  onFixSpelling: () => void;
  onMakeShorter: () => void;
  onMakeLonger: () => void;
  onEmojify: () => void;
  onTldr: () => void;
  onTranslate: (language: Language) => void;
  onTone: (tone: string) => void;
  onCompleteSentence: () => void;
};

export const AIDropdown = ({
  onCompleteSentence,
  onEmojify,
  onFixSpelling,
  onMakeLonger,
  onMakeShorter,
  onSimplify,
  onTldr,
  onTone,
  onTranslate,
}: AIDropdownProps) => {
  const handleTone = useCallback(
    (tone: string) => () => onTone(tone),
    [onTone],
  );
  const handleTranslate = useCallback(
    (language: Language) => () => onTranslate(language),
    [onTranslate],
  );

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Toolbar.Button
          className="text-purple-500 hover:text-purple-600 active:text-purple-600 dark:text-purple-400 dark:hover:text-purple-300 dark:active:text-purple-400"
          activeClassname="text-purple-600 hover:text-purple-600 dark:text-purple-400 dark:hover:text-purple-200"
        >
          <Icon name="Sparkles" className="mr-1" />
          AI Tools
          <Icon name="ChevronDown" className="w-2 h-2 ml-1" />
        </Toolbar.Button>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Surface className="p-2 min-w-[10rem]">
          <Dropdown.Item onClick={onSimplify}>
            <DropdownButton>
              <Icon name="CircleSlash" />
              Simplify
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onFixSpelling}>
            <DropdownButton>
              <Icon name="Eraser" />
              Fix spelling & grammar
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeShorter}>
            <DropdownButton>
              <Icon name="ArrowLeftToLine" />
              Make shorter
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onMakeLonger}>
            <DropdownButton>
              <Icon name="ArrowRightToLine" />
              Make longer
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger>
              <DropdownButton>
                <Icon name="Mic" />
                Change tone
                <Icon name="ChevronRight" className="w-4 h-4 ml-auto" />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                {tones.map((tone) => (
                  <Dropdown.Item
                    onClick={handleTone(tone.value)}
                    key={tone.value}
                  >
                    <DropdownButton>{tone.label}</DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          <Dropdown.Item onClick={onTldr}>
            <DropdownButton>
              {/* @ts-expect-error -- idk */}
              <Icon name="Ellipsis" />
              Tl;dr:
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Item onClick={onEmojify}>
            <DropdownButton>
              <Icon name="SmilePlus" />
              Emojify
            </DropdownButton>
          </Dropdown.Item>
          <Dropdown.Sub>
            <Dropdown.SubTrigger>
              <DropdownButton>
                <Icon name="Languages" />
                Translate
                <Icon name="ChevronRight" className="w-4 h-4 ml-auto" />
              </DropdownButton>
            </Dropdown.SubTrigger>
            <Dropdown.SubContent>
              <Surface className="flex flex-col min-w-[15rem] p-2 max-h-[20rem] overflow-auto">
                {languages.map((lang) => (
                  <Dropdown.Item
                    onClick={handleTranslate(lang.value)}
                    key={lang.value}
                  >
                    <DropdownButton>{lang.label}</DropdownButton>
                  </Dropdown.Item>
                ))}
              </Surface>
            </Dropdown.SubContent>
          </Dropdown.Sub>
          <Dropdown.Item onClick={onCompleteSentence}>
            <DropdownButton>
              <Icon name="PenLine" />
              Complete sentence
            </DropdownButton>
          </Dropdown.Item>
        </Surface>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
