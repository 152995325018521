import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { titleCase } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { IconSelector } from "../web-links/icon-selector";

const storiesSchema = z.object({
  type: z.literal("stories"),
  storyDuration: z.number().min(1).max(60),
});

const verticalHorizontalSchema = z.object({
  type: z.literal("vertical"),
  showThumbnail: z.boolean(),
});

const horizontalSchema = z.object({
  type: z.literal("horizontal"),
  showThumbnail: z.boolean(),
});

const gridSchema = z.object({
  type: z.literal("grid"),
  backgroundColor: z
    .string()
    .refine((value) => /^#[0-9A-Fa-f]{6}$/.test(value), {
      message: "Invalid hex color code. Must be in format #123456",
    })
    .optional(),
  icon: z.string().optional(),
});

// Combine schemas into a discriminated union
const postablesExtrasSchema = z.discriminatedUnion("type", [
  storiesSchema,
  verticalHorizontalSchema,
  horizontalSchema,
  gridSchema,
]);

type PostablesExtrasType = z.infer<typeof postablesExtrasSchema>;

type PostablesExtrasModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: PostablesExtrasType) => void;
  displayType: PostablesExtrasType["type"];
};

export function PostablesExtrasModal({
  isOpen,
  onClose,
  onSubmit,
  displayType,
}: PostablesExtrasModalProps) {
  const form = useForm<PostablesExtrasType>({
    resolver: zodResolver(postablesExtrasSchema),
    defaultValues: {
      type: displayType,
      ...(displayType === "grid" ? { backgroundColor: "", icon: "" } : {}),
      ...(displayType === "stories" ? { storyDuration: 1 } : {}),
      ...(displayType === "vertical" || displayType === "horizontal"
        ? { showThumbnail: false }
        : {}),
    },
  });

  const handleSubmit = (data: PostablesExtrasType) => {
    onSubmit(data);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {`${titleCase(displayType)} Postable Extras`}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4"
          >
            {displayType === "grid" ? (
              <>
                <FormField
                  control={form.control}
                  name="backgroundColor"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Background Color</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <IconSelector form={form} />
              </>
            ) : null}

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
