import { Separator } from "@/components/ui/separator";

import { SidebarNav } from "@/components/settings/components/sidebar-nav";
import { Outlet } from "@tanstack/react-router";

const sidebarNavItems = [
  {
    title: "Users",
    href: "/settings/users",
  },
  {
    title: "Organizations",
    href: "/settings/organizations",
  },
  {
    title: "Connections",
    href: "/settings/connections",
  },
];

export function Settings() {
  return (
    <div className="space-y-6 p-10 pb-16 md:block">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
        <p className="text-muted-foreground">
          Manage settings for your account.
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
